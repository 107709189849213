<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    :scrollable="false"
    :persistent="persistent"
  >
    <template #activator="activatorProps">
      <slot name="activator" v-bind="activatorProps"></slot>
    </template>
    <v-card height="auto" max-height="auto">
      <div class="flex-align" :class="{ 'my-2 pl-4 pr-2': pad }">
        <div v-if="title" class="text-5">{{ title }}</div>
        <v-spacer />
        <v-btn v-if="!persistent" @click="close" size="35" variant="text" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <div
        v-if="subtitle"
        :class="{ 'mb-4 px-4': pad }"
        class="text-3.6 line-height-none opacity-80"
      >
        <div v-html="subtitle"></div>
      </div>
      <template v-if="$slots.top">
        <v-divider v-if="divider" />
        <slot name="top" />
      </template>
      <template v-if="$slots.default || message || inputs">
        <v-divider v-if="divider" />
        <v-sheet
          :class="{
            'pa-4': pad && !noPadContent,
            'highlight!': invertHighlight,
          }"
          :max-height="maxHeight"
          :height="height"
          style="overflow-y: auto"
        >
          <slot v-bind="{ close }">
            <span v-html="message"></span>
          </slot>
          <v-dynamic-form
            v-if="inputs"
            v-model="form"
            v-model:valid="validForm"
            :inputs="inputs"
            :defaults="inputsDefaults"
            hide-actions
          />
        </v-sheet>
      </template>
      <v-divider v-if="divider" />
      <div
        v-if="!hideActions"
        class="flex-align gap-2"
        :class="{ 'pa-2': pad, highlight: !invertHighlight }"
      >
        <slot name="actions" v-bind="{ close }">
          <v-spacer />
          <v-btn
            v-if="!hideCancelAction"
            @click="close"
            :density="density"
            variant="elevated"
          >
            {{ cancelText }}
          </v-btn>
          <v-btn
            v-if="action || doneAction"
            @click="ok"
            :disabled="doneDisabled"
            :loading="loading"
            :density="density"
            color="primary"
            variant="elevated"
          >
            {{ doneText }}
          </v-btn>
        </slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { FieldInputs } from "~/layers/ui/modules/VDynamicForm/runtime/types";

export default defineComponent({
  name: "ActionDialog",
  props: {
    title: String,
    subtitle: String,
    message: String,
    pad: { type: Boolean, default: false },
    noPadContent: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    inactive: { type: Boolean, default: false },
    density: String as PropType<any>,
    divider: { type: Boolean, default: true },
    invertHighlight: { type: Boolean, default: false },
    height: { type: [Number, String] },
    width: { type: [Number, String], default: 450 },
    maxHeight: { type: [Number, String], default: 400 },
    doneText: { type: String, default: "Done" },
    cancelText: { type: String, default: "Cancel" },
    hideCancelAction: { type: Boolean, default: false },
    hideActions: { type: Boolean, default: false },
    doneAction: { type: Function as PropType<{ (...a: any): any }> },
    cancelAction: { type: Function as PropType<{ (...a: any): any }> },
    action: { type: Function as PropType<{ (...a: any): any }> },
    persistent: { type: Boolean, default: false },
    eager: { type: Boolean, default: false },
    inputs: { type: [Object, Array] as PropType<FieldInputs> },
    inputsDefaults: { type: Object },
  },
  data: () => ({
    dialog: false,
    loading: false,
    form: {} as any,
    validForm: false,
  }),
  computed: {
    doneDisabled() {
      return (
        this.disabled || this.inactive || (!!this.inputs && !this.validForm)
      );
    },
  },
  mounted() {
    if (this.eager) {
      this.dialog = true;
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      if (this.cancelAction) {
        this.cancelAction();
      }
    },
    ok() {
      this.loading = true;
      const action = this.doneAction || this.action;

      if (!action) {
        this.dialog = false;
        return;
      }

      const inputs = this.inputs ? Object.assign({}, this.form) : undefined;

      Promise.resolve(action(inputs))
        .then((result: any) => {
          if (result !== false) {
            this.dialog = false;
          }
        })
        .catch(() => null)
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    dialog(value: boolean) {
      this.$emit(value ? "opened" : "closed");
    },
  },
});
</script>
